<template>
  <v-container style="height: 65vh">
    <v-breadcrumbs :items="items" dir="rtl">
      <template v-slot:divider>
        <v-icon icon="mdi-forward" style="transform: scaleX(-1)"></v-icon>
      </template>
    </v-breadcrumbs>
    <br />
    <v-card>
      <v-container v-if="authenticated === false">
        <v-card variant="tonal" class="bbttt">
          <v-btn
            block
            class="text-none"
            color="#5865f2"
            size="x-large"
            variant="flat"
            @click="goLogin"
          >
            <div>
              <v-icon>mdi mdi-login-variant</v-icon>
              <span>تسجيل الدخول</span>
            </div>

            <v-icon>mdi mdi-skip-backward</v-icon>
          </v-btn>
        </v-card>
        <hr />
      </v-container>
      <v-container>
        <v-card variant="tonal" class="bbttt">
          <v-btn
            block
            class="text-none"
            color="rgb(255 255 255)"
            size="x-large"
            prepend-icon=""
            variant="flat"
            @click="ModelChangeLanguage = true"
          >
            <div>
              <v-icon>mdi mdi-earth</v-icon>
              <span>اللغة</span>
            </div>

            <v-icon>mdi mdi-skip-backward</v-icon>
          </v-btn>
        </v-card>
        <hr style="margin-block: 15px" />
        <v-card variant="tonal" class="bbttt">
          <v-btn
            block
            class="text-none"
            color="rgb(255 255 255)"
            size="x-large"
            variant="flat"
          >
            <div>
              <v-icon>mdi mdi-phone-classic</v-icon>
              <span>تواصل معنا</span>
            </div>

            <v-icon>mdi mdi-skip-backward</v-icon>
          </v-btn>
        </v-card>
      </v-container>
      <v-container v-if="authenticated === true">
        <v-card variant="tonal" class="bbttt">
          <v-btn
            block
            class="text-none"
            color="rgb(242 88 88)"
            style="color: white"
            size="x-large"
            :loading="loadingLogout"
            variant="flat"
            @click="logout"
          >
            <div>
              <v-icon>mdi mdi-logout</v-icon>
              <span>تسجيل الخروج</span>
            </div>

            <v-icon>mdi mdi-skip-backward</v-icon>
          </v-btn>
        </v-card>
        <hr />
      </v-container>
    </v-card>
  </v-container>
  <!-- - Dailog for show info to user -->
  <v-dialog v-model="ModelChangeLanguage" max-width="500px">
    <v-card>
      <v-card-title class="headline justify-center">
        <v-radio-group v-model="Language">
          <v-radio label="عربي" value="ar"></v-radio>
          <v-radio label="English" value="en"></v-radio>
        </v-radio-group>
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="ChangeLanguage">
          تم
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- - Dailog for show info to user -->
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["authenticated", "user", "token"]),
    ...mapGetters(["someGetter"]),
  },
  data: () => ({
    items: [
      {
        title: "الصفحة الرئيسية",
        disabled: false,
        to: "",
      },
      {
        title: "الملف الشخصي",
        disabled: true,
        href: "breadcrumbs_link_2",
      },
    ],
    loadingLogout: false,
    ModelChangeLanguage: false,
    Language: localStorage.getItem("language"),
  }),
  methods: {
    goLogin() {
      this.$router.push({ path: "/ar/login" });
    },
    ChangeLanguage() {
      localStorage.setItem("language", this.Language);
      this.$store.commit('SET_LANGUAGE', this.Language);
      this.ModelChangeLanguage = false;
      this.$router.push({ path: `/${this.Language}/profile` });
    },
    logout() {
      this.loadingLogout = true;
      this.$store.dispatch("logoutUser");
      this.loadingLogout = false;
    },
  },
};
</script>

<style >
.bbttt .v-btn__content {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.bbttt .v-btn__content div {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  align-items: center;
}
</style>

