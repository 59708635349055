<template>
  <v-app>
    <v-container fluid>
      <component :is="language === 'ar' ? 'HeaderAR' : 'HeaderEN'" />
      <v-row justify="center">
        <v-main>
          <router-view />
        </v-main>
      </v-row>
    </v-container>
    <component :is="language === 'ar' ? 'FooterAR' : 'FooterEN'" />
  </v-app>
</template>

<script>
import HeaderAR from "./navigation/ar/Header.vue";
import FooterAR from "./navigation/ar/Footer.vue";
import HeaderEN from "./navigation/en/Header.vue";
import FooterEN from "./navigation/en/Footer.vue";

export default {
  name: "App",
  computed: {
    language() {
      return this.$store.state.language;
    },
  },
  components: {
    HeaderAR,
    FooterAR,
    HeaderEN,
    FooterEN,
  },
};
</script>
