import { createStore } from "vuex";
import Api from "@/api/api.js";

const state = {
  language: null,
  authenticated: false,
  user: null,
  token: null,
};

const getters = {};

const mutations = {
  SET_AUTHENTICATED(state, value) {
    state.authenticated = value;
  },
  SET_USER(state, user) {
    state.user = user;
  },
  SET_TOKEN(state, token) {
    state.token = token;
  },
  SET_LANGUAGE(state, language) {
    state.language = language;
  },
};

const actions = {
  checkAuthStatus({ commit }) {
    const storedUser = localStorage.getItem("userShahrazad");
    const storedToken = localStorage.getItem("tokenShahrazad");
    const storedLanguage = localStorage.getItem("language");

    if (storedUser && storedToken) {
      const user = JSON.parse(storedUser);
      const token = JSON.parse(storedToken);
      commit("SET_AUTHENTICATED", true);
      commit("SET_USER", user);
      commit("SET_TOKEN", token);
    } else {
      commit("SET_AUTHENTICATED", false);
      commit("SET_USER", null);
      commit("SET_TOKEN", null);
    }
    if (!storedLanguage) {
      const defaultLanguage = "ar";
      commit("SET_LANGUAGE", defaultLanguage);
      localStorage.setItem("language", defaultLanguage);
    } else {
      commit("SET_LANGUAGE", storedLanguage);
    }
  },
  async registerUser({ commit }, { phone, password_show, name, router }) {
    try {
      const response = await Api.register({
        phone: phone,
        password_show: password_show,
        name: name,
      });
      localStorage.setItem(
        "phone",
        JSON.stringify(phone)
      );


      router.push({ path: "/checkOtp" });
    } catch (error) {
      commit("SET_AUTHENTICATED", false);
      throw error;
    }
  },
  async loginUser({ commit }, { phone, password, router }) {
    try {
      const response = await Api.login({
        phone: phone,
        password: password,
      });
      commit("SET_AUTHENTICATED", true);
      commit("SET_USER", response.data.results);
      commit("SET_TOKEN", response.data.results.token);
      const storedLanguage = localStorage.getItem("language");

      localStorage.setItem(
        "userShahrazad",
        JSON.stringify(response.data.results)
      );
      localStorage.setItem(
        "tokenShahrazad",
        JSON.stringify(response.data.results.token)
      );

      router.push({ path: `/${storedLanguage}/profile` });
    } catch (error) {
      commit("SET_AUTHENTICATED", false);
      throw error;
    }
  },
  async logoutUser({ commit }) {
    try {
      const response = await Api.logout();
      commit("SET_AUTHENTICATED", false);
      commit("SET_USER", null);
      commit("SET_TOKEN", null);
      console.log(response)
      localStorage.removeItem("userShahrazad");
      localStorage.removeItem("tokenShahrazad");
    } catch (error) {
      commit("SET_AUTHENTICATED", false);
      throw error;
    }
  },
};

export default createStore({
  state,
  getters,
  mutations,
  actions,
  modules: {},
});
