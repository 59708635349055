<template>
  <v-sheet class="pa-12" rounded style="margin-block: 10%" v-if="!retry">
    <v-card class="mx-auto px-6 py-8" max-width="344">
      <v-card-title class="text-center">sign in</v-card-title>
      <br />

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        action="/dashboards/analytical"
      >
        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          density="compact"
          placeholder="Enter the phone number"
          label="phone number"
          prepend-inner-icon="mdi-phone"
          variant="outlined"
        ></v-text-field>

        <br />

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          :append-inner-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
          :type="show1 ? 'text' : 'password'"
          density="compact"
          label="password"
          placeholder="Enter the password"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          @click:append-inner="show1 = !show1"
        ></v-text-field>

        <br />

        <v-btn
          :disabled="!valid"
          color="info"
          :loading="loading"
          block
          class="mr-4"
          submit
          @click="submit"
          >sign in</v-btn
        >
      </v-form>
      <br />
      <div class="text-center">
        <router-link to="/en/PasswordReset" style="color: #5c6bc0">
          did you forget your password ?
        </router-link>
      </div>
      <br />
      <div
        style="
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;
        "
      >
        <p>Don't have an account?</p>
        <router-link to="/en/register" style="color: #5c6bc0">
          Create a new account
        </router-link>
      </div>
    </v-card>
  </v-sheet>
  <v-sheet class="pa-12" rounded style="margin-block: 10%" v-else>
    <v-card class="mx-auto px-6 py-8" max-width="344">
      <v-card-title class="text-center">Request verification code</v-card-title>
      <br />
      <div class="text-center">
        <v-btn :loading="loadingRetryOtp" style="color: #5c6bc0" @click="getRetry">
          Request verification code
        </v-btn>
      </div>
      <br />
    </v-card>
  </v-sheet>
  <!-- - Dailog for show info to user -->
  <v-dialog v-model="dialogData.open" max-width="500px">
    <v-toolbar :color="dialogData.color" dense />
    <v-card>
      <v-card-title class="headline justify-center">
        {{ dialogData.bodyText }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialogData.open = false">
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- - Dailog for show info to user -->
</template>

<script>
import { mapState, mapGetters } from "vuex";
import Api from "@/api/api.js";

export default {
  computed: {
    ...mapState(["authenticated", "user", "token"]),
    ...mapGetters(["someGetter"]),
  },
  created() {
    if (this.authenticated === true) {
      this.$router.push({ path: "/en/profile" });
    }
  },
  data: () => ({
    valid: true,
    password: "",
    retry: false,
    loadingRetryOtp: false,
    show1: false,
    loading: false,
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => (v && v.length > 5) || "Password must be more than 6",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "Phone number required",
      (v) => (v && v.length > 10) || "The phone number must be 11 digits",
    ],
    checkbox: false,
    // message
    dialogData: {
      open: false,
      color: "primary",
      bodyText: "test",
    },
    // message
  }),

  methods: {
    togglePasswordVisibility() {
      this.show1 = !this.show1;
    },
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch("loginUser", {
          phone: this.phone,
          password: this.password,
          router: this.$router,
        });

        this.loading = false;
      } catch (error) {
        if (
          error.response &&
          error.response.data &&
          error.response.data.results
        ) {
          this.showDialogfunction(error.response.data.results, "#FF5252");
          if(error.response.data.results  === 'يجب ان تقوم بالتحقق من رقم الهاتف'){
            this.retry = true
          }
        } else {
          this.showDialogfunction("Error, please try again", "#FF5252");
        }

        this.loading = false;
      }
    },
    async getRetry() {
      this.loadingRetryOtp = true;

      try {
        const response = await Api.retryOtp();
        this.loadingRetryOtp = false;
        this.$router.push({ path: '/en/checkOtp' });

      } catch (error) {
        this.showDialogfunction("Error, please try again", "#FF5252");
        this.loadingRetryOtp = false;
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true;
      this.dialogData.bodyText = bodyText;
      this.dialogData.color = color;
    },
  },
};
</script>