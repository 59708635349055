<template>
  <v-container >
    <v-card class="text-right" :loading="loading"> 
        <v-card-text>
            <div>
                <v-img
                  :src="content_url + Services.image"
                  class="bg-grey-lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        v-if="loadingImg"
                        indeterminate
                        color="grey-lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
            </div>
            <v-card-title>{{ Services.name }}</v-card-title>
            <v-card-text>
                {{ Services.description }}
            </v-card-text>
        </v-card-text>
    </v-card>
  </v-container>
</template>
  
<script>
import Api from "@/api/api.js";

export default {
  data() {
    return {
      id: this.$route.params.id,
      Services: {},
      loading: false,
      loadingImg: false,
      content_url: "",
    };
  },
  created() {
    this.getOneService();
  },
  methods: {
    async getOneService() {
      this.loadingImg = true;
      this.loading = true;
      const response = await Api.getOneService(this.id);
      this.Services = response.data.results;
      this.content_url = response.data.content_url;
      this.loading = false;
      this.loadingImg = false;

      console.log(this.Services);
    },
  },
};
</script>
  
<style>

</style>
  