<template>
  <v-container>
    <v-card class="text-right" title="خدماتنا الأضافية">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <Carousel v-bind="settings" :breakpoints="breakpoints">
        <Slide v-for="(Service, index) in Services" :key="index">
          <div class="carousel__item">
            <router-link :to="`/ar/getOneService/${Service._id}`" >
              <v-card>
                <v-img
                  :src="content_url + Service.image"
                  class="bg-grey-lighten-2"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        v-if="loadingImg"
                        indeterminate
                        color="grey-lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
                <v-card-title class="text-h6 color" >
                  {{ Service.name }}
                </v-card-title>
              </v-card>
            </router-link>
          </div>
        </Slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </Carousel>
    </v-card>
  </v-container>
</template>
  
  <script>
import { defineComponent } from "vue";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import Api from "@/api/api.js";

import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "Breakpoints",
  components: {
    Carousel,
    Slide,
    Navigation,
    Pagination,
  },
  data: () => ({
    Services: [],
    loading: false,
    loadingImg: false,
    content_url: "",

    settings: {
      itemsToShow: 1,
      snapAlign: "center",
    },
    breakpoints: {
      // 700px and up
      700: {
        itemsToShow: 3.5,
        snapAlign: "center",
      },
      // 1024 and up
      1024: {
        itemsToShow: 4,
        snapAlign: "start",
      },
    },
  }),
  created() {
    this.getServices();
  },
  methods: {
    async getServices() {
      this.loading = true;
      this.loadingImg = true;
      const response = await Api.getServices();
      this.Services = response.data.results;
      this.content_url = response.data.content_url;
      this.loading = false;
      this.loadingImg = false;

    },
  },
});
</script>
  
  

