import { createApp } from 'vue';
import '@/assets/style.css';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';
import axios from 'axios';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

axios.defaults.baseURL = 'https://api.shahrazad-iq.com/api/customer_web';

const app = createApp(App);
app.use(router);
app.use(store);
app.use(vuetify);
app.component('VueDatePicker', VueDatePicker);
store.dispatch("checkAuthStatus");

app.config.globalProperties.$axios = axios;

app.mount('#app');
