<template>
  <v-sheet class="pa-12" rounded style="margin-block: 10%">
    <v-card class="mx-auto px-6 py-8" max-width="344">
      <v-card-title class="text-center">أنشاء حساب جديد</v-card-title>
      <br />

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        action="/dashboards/analytical"
      >
        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          density="compact"
          placeholder="أدخال رقم الهاتف"
          label="رقم الهاتف"
          prepend-inner-icon="mdi-phone"
          variant="outlined"
        ></v-text-field>

        <br />
        <v-text-field
          v-model="name"
          :rules="nameRules"
          density="compact"
          placeholder="ادخل أسمك"
          label="أسم المستخدم"
          prepend-inner-icon="mdi mdi-alpha-n-box-outline"
          variant="outlined"
        ></v-text-field>

        <br />

        <v-text-field
          v-model="password_show"
          :rules="passwordRules"
          :append-inner-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
          :type="show1 ? 'text' : 'password'"
          density="compact"
          label="كلمة المرور"
          placeholder="أدخل كلمة المرور"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          @click:append-inner="show1 = !show1"
        ></v-text-field>

        <br />

        <v-btn
          :disabled="!valid"
          color="info"
          :loading="loading"
          block
          class="mr-4"
          submit
          @click="submit"
          >أنشاء الحساب</v-btn
        >
      </v-form>
      <br />
      <div
        style="
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;
        "
      >
        <p>لديك حساب بالفعل ؟</p>
        <router-link to="/ar/login" style="color: #5c6bc0">
          تسجيل الدخول
        </router-link>
      </div>
    </v-card>
  </v-sheet>
  <!-- - Dailog for show info to user -->
  <v-dialog v-model="dialogData.open" max-width="500px">
    <v-toolbar :color="dialogData.color" dense />
    <v-card>
      <v-card-title class="headline justify-center">
        {{ dialogData.bodyText }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialogData.open = false">
          تم
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- - Dailog for show info to user -->
</template>
  
  <script>
import { mapState, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState(["authenticated", "user", "token"]),
    ...mapGetters(["someGetter"]),
  },
  created() {
    if (this.authenticated === true) {
      this.$router.push({ path: "/ar/profile" });
    }
  },
  data: () => ({
    valid: true,
    name: "",
    password_show: "",
    show1: false,
    loading: false,
    phone: "",
    passwordRules: [
      (v) => !!v || "كلمة المرور مطلوبة",
      (v) => (v && v.length > 5) || "يجب أن تكون كلمة المرور أكثر من 6",
    ],
    nameRules: [
      (v) => !!v || "أسم المستخدم مطلوب",
    ],
    phoneRules: [
      (v) => !!v || "رقم الهاتف مطلوب",
      (v) => (v && v.length > 10) || "يجب ان يكون رقم الهاتف 11 رقم",
    ],
    checkbox: false,
    // message
    dialogData: {
      open: false,
      color: "primary",
      bodyText: "test",
    },
    // message
  }),

  methods: {
    togglePasswordVisibility() {
      this.show1 = !this.show1;
    },
    async submit() {
      this.loading = true;

      try {
        await this.$store.dispatch("registerUser", {
          phone: this.phone,
          password_show: this.password_show,
          name: this.name,
          router: this.$router,
        });

        this.loading = false;
      } catch (error) {
        this.showDialogfunction(
          "فشل, أسم المستخدم أو كلمة المرور خطاء",
          "#FF5252"
        );
        this.loading = false;
      }
    },
    showDialogfunction(bodyText, color) {
      this.dialogData.open = true;
      this.dialogData.bodyText = bodyText;
      this.dialogData.color = color;
    },
  },
};
</script>