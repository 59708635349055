<template>
  <v-footer class="bg-indigo-lighten-1 text-center d-flex flex-column">
    <v-card-title>Call Us</v-card-title>
    <div>
    <v-btn
      v-if="data.facebook"
      class="mx-4"
      icon="mdi-facebook"
      variant="text"
      @click="openFacebook"
    ></v-btn>
    <v-btn
      v-if="data.whatsapp"
      class="mx-4"
      icon="mdi-whatsapp"
      variant="text"
      @click="openWhatsapp"
    ></v-btn>
    <v-btn
      v-if="data.instagram"
      class="mx-4"
      icon="mdi-instagram"
      variant="text"
      @click="openInstagram"
    ></v-btn>
    <v-btn
      v-if="data.email"
      class="mx-4"
      icon="mdi-email"
      variant="text"
      @click="openEmail"
    ></v-btn>
    <v-btn
      v-if="data.phone"
      class="mx-4"
      icon="mdi-phone"
      variant="text"
      @click="openPhone"
    ></v-btn>
  </div>

    <!-- <div class="pt-0">
      Phasellus feugiat arcu sapien, et iaculis ipsum elementum sit amet. Mauris
      cursus commodo interdum. Praesent ut risus eget metus luctus accumsan id
      ultrices nunc. Sed at orci sed massa consectetur dignissim a sit amet dui.
      Duis commodo vitae velit et faucibus. Morbi vehicula lacinia malesuada.
      Nulla placerat augue vel ipsum ultrices, cursus iaculis dui sollicitudin.
      Vestibulum eu ipsum vel diam elementum tempor vel ut orci. Orci varius
      natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
    </div> -->

    <v-divider></v-divider>

    <div>{{ data.location }} — <strong>Location</strong></div>
  </v-footer>
</template>

<script>
import Api from "@/api/api.js";

export default {
  data: () => ({
    loading: false,
    data:{}
  }),
  created() {
    this.getFooter();
  },
  methods: {
    async getFooter() {
      this.loading = true;
      const response = await Api.getContactUs();
      this.data = response.data.results;
      this.content_url = response.data.content_url;
      this.loading = false;
    },
    openFacebook() {
      if (this.data.facebook) {
        window.open(this.data.facebook, '_blank');
      }
    },
    openWhatsapp() {
      if (this.data.whatsapp) {
        window.open(this.data.whatsapp, '_blank');
      }
    },
    openInstagram() {
      if (this.data.instagram) {
        window.open(this.data.instagram, '_blank');
      }
    },
    openEmail() {
      if (this.data.email) {
        window.open(this.data.email, '_blank');
      }
    },
    openPhone() {
      if (this.data.phone) {
        window.open(this.data.phone, '_blank');
      }
    },
  },
};
</script>