<template>
  <v-sheet class="pa-12" rounded style="margin-block: 10%" v-if="!retry">
    <v-card class="mx-auto px-6 py-8" max-width="344">
      <v-card-title class="text-center">اعادة تعيين كلمة المرور</v-card-title>
      <br />

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        action="/dashboards/analytical"
      >
        <v-text-field
          v-model="phone"
          :rules="phoneRules"
          density="compact"
          placeholder="اردخل رقم الهاتف"
          label="رقم الهاتف"
          prepend-inner-icon="mdi-phone"
          variant="outlined"
        ></v-text-field>

        <br />

        <v-text-field
          v-model="password"
          :rules="passwordRules"
          :append-inner-icon="show1 ? 'mdi-eye-off' : 'mdi-eye'"
          :type="show1 ? 'text' : 'password'"
          density="compact"
          label="كلمة المرور الجديدة"
          placeholder="أدخل كلمة المرور الجديدة"
          prepend-inner-icon="mdi-lock-outline"
          variant="outlined"
          @click:append-inner="show1 = !show1"
        ></v-text-field>

        <br />

        <v-btn
          :disabled="!valid"
          color="info"
          :loading="loading"
          block
          class="mr-4"
          submit
          @click="submitt"
          >اعادة التعيين</v-btn
        >
      </v-form>
    </v-card>
  </v-sheet>
    <!-- - Dailog for show info to user -->
    <v-dialog v-model="dialogData.open" max-width="500px">
    <v-toolbar :color="dialogData.color" dense />
    <v-card>
      <v-card-title class="headline justify-center">
        {{ dialogData.bodyText }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialogData.open = false">
          تم
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- - Dailog for show info to user -->

</template>

<script>
import { mapState, mapGetters } from "vuex";
import Api from "@/api/api.js";

export default {
  computed: {
    ...mapState(["authenticated", "user", "token"]),
    ...mapGetters(["someGetter"]),
  },
  created() {
    if (this.authenticated === true) {
      this.$router.push({ path: "/ar/profile" });
    }
  },
  data: () => ({
    valid: true,
    password: "",
    retry: false,
    loadingRetryOtp: false,
    show1: false,
    loading: false,
    passwordRules: [
      (v) => !!v || "كلمة المرور مطلوبة",
      (v) => (v && v.length > 5) || "يجب أن تكون كلمة المرور أكثر من 6",
    ],
    phone: "",
    phoneRules: [
      (v) => !!v || "رقم الهاتف مطلوب",
      (v) => (v && v.length > 10) || "يجب ان يكون رقم الهاتف 11 رقم",
    ],
    checkbox: false,
    // message
    dialogData: {
      open: false,
      color: "primary",
      bodyText: "test",
    },
    // message
  }),

  methods: {
    togglePasswordVisibility() {
      this.show1 = !this.show1;
    },
    async submitt() {
  this.loading = true;

  try {
    const response = await Api.PasswordReset({
      phone: this.phone,
      password: this.password,
    });
    console.log(response.data.message);
    this.loading = false;

    // عرض رسالة النجاح
    this.showDialogfunction(response.data.message, "#5c6bc0");

    // تأخير لمدة 10 ثوانٍ ومن ثم توجيه المستخدم  07724275947
    setTimeout(() => {
      this.$router.push({ path: "/ar/login" });
    }, 1000);

  } catch (error) {
    this.showDialogfunction("خطأ، يرجى إعادة المحاولة", "#FF5252");
    this.loading = false;
  }
},

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true;
      this.dialogData.bodyText = bodyText;
      this.dialogData.color = color;
    },
  },
};
</script>