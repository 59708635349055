// ar
import { createRouter, createWebHistory } from "vue-router";
import HomeViewAR from "../views/ar/HomeView.vue";
import profileViewAR from "../views/ar/profileView.vue";
import loginViewAR from "../views/ar/auth/loginView.vue";
import registerViewAR from "../views/ar/auth/registerView.vue";
import checkOtpViewAR from "../views/ar/auth/checkOtpView.vue";
import PasswordResetViewAR from "../views/ar/auth/PasswordResetView.vue";
import ServicesViewAR from "../views/ar/ServicesView.vue";
import getOneServiceViewAR from "../views/ar/getOneServiceView.vue";
// ar
// en
import HomeViewEN from "../views/en/HomeView.vue";
import profileViewEN from "../views/en/profileView.vue";
import loginViewEN from "../views/en/auth/loginView.vue";
import registerViewEN from "../views/en/auth/registerView.vue";
import checkOtpViewEN from "../views/en/auth/checkOtpView.vue";
import PasswordResetViewEN from "../views/en/auth/PasswordResetView.vue";
import ServicesViewEN from "../views/en/ServicesView.vue";
import getOneServiceViewEN from "../views/en/getOneServiceView.vue";
// en

var Language = localStorage.getItem("language") || "ar";


const routes = [
  // ar
  {
    path: Language === "en" ? "/en/" : "/",
    name: "home"+Language,
    component: Language === "en" ? HomeViewEN : HomeViewAR,
  },
  {
    path: "/ar",
    name: "home",
    component: HomeViewAR,
  },
  {
    path: "/ar/Services",
    name: "services",
    component: ServicesViewAR,
  },
  {
    path: "/ar/getOneService/:id",
    name: "getOneService",
    component: getOneServiceViewAR,
  },
  {
    path: "/ar/profile",
    name: "profile",
    component: profileViewAR,
  },
  {
    path: "/ar/login",
    name: "login",
    component: loginViewAR,
  },
  {
    path: "/ar/register",
    name: "register",
    component: registerViewAR,
  },
  {
    path: "/ar/checkOtp",
    name: "checkOtp",
    component: checkOtpViewAR,
  },
  {
    path: "/ar/PasswordReset",
    name: "PasswordReset",
    component: PasswordResetViewAR,
  },
  // ar
  // en
  {
    path: "/en/",
    name: "homeEN",
    component: HomeViewEN,
  },
  {
    path: "/en/Services",
    name: "servicesEN",
    component: ServicesViewEN,
  },
  {
    path: "/en/getOneService/:id",
    name: "getOneServiceEN",
    component: getOneServiceViewEN,
  },
  {
    path: "/en/profile",
    name: "profileEN",
    component: profileViewEN,
  },
  {
    path: "/en/login",
    name: "loginEN",
    component: loginViewEN,
  },
  {
    path: "/en/register",
    name: "registerEN",
    component: registerViewEN,
  },
  {
    path: "/en/checkOtp",
    name: "checkOtpEN",
    component: checkOtpViewEN,
  },
  {
    path: "/en/PasswordReset",
    name: "PasswordResetEN",
    component: PasswordResetViewEN,
  },
  // en
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
