<template>
  <v-container>
    <Search></Search>
    <Services></Services>
    <Advertisements></Advertisements>
  </v-container>
</template>

<script>
import Search from "@/components/homeEN/Search.vue";
import Services from "@/components/homeEN/Services.vue";
import Advertisements from "@/components/homeEN/Advertisements.vue";

export default {
  name: "Home",
  components: {
    Search,
    Services,
    Advertisements,
  }
};
</script>
