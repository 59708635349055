<template>
  <v-sheet class="pa-12" rounded style="margin-block: 10%">
    <v-card class="mx-auto px-6 py-8" max-width="344">
      <v-card-title class="text-center"
        >أدخل رمز التحقق الذي تم <br />
        ارساله اليك عبر الواتساب</v-card-title
      >
      <br />

      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        action="/dashboards/analytical"
      >
        <v-otp-input
          focus-all
          :loading="loadingOtp"
          v-model="otp"
          :rules="otpRules"
        ></v-otp-input>

        <v-btn
          :disabled="!valid"
          color="info"
          :loading="loading"
          block
          class="mr-4"
          submit
          @click="submit"
          >أرسال الرمز</v-btn
        >
      </v-form>
      <br />
      <div
        style="
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: center;
        "
      >
        <p>لم تستلم رمز التحقق ؟</p>
        <p v-if="timer > 0">
          الرجاء انتضار أنتهاء الوقت لاعادة ارسال الرمز ( {{ timer }} )
        </p>
        <v-btn
          v-else
          style="color: #5c6bc0"
          :loading="loadingRetryOtp"
          :disabled="timer > 0"
          @click="retryOtp"
        >
          أعادة ارسال الرمز
        </v-btn>
      </div>
    </v-card>
  </v-sheet>
  <!-- - Dailog for show info to user -->
  <v-dialog v-model="dialogData.open" max-width="500px">
    <v-toolbar :color="dialogData.color" dense />
    <v-card>
      <v-card-title class="headline justify-center">
        {{ dialogData.bodyText }}
      </v-card-title>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" text @click="dialogData.open = false">
          تم
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- - Dailog for show info to user -->
</template>
  
  <script>
import { mapState, mapGetters } from "vuex";
import Api from "@/api/api.js";

export default {
  computed: {
    ...mapState(["authenticated", "user", "token"]),
    ...mapGetters(["someGetter"]),
  },
  created() {
    if (this.authenticated === true) {
      this.$router.push({ path: "/ar/profile" });
    }
  },
  data: () => ({
    valid: true,
    loadingOtp: false,
    loadingRetryOtp: false,
    loading: false,
    otp: "",
    otpRules: [(v) => !!v || "يجب أدخال رمز التحقق"],
    checkbox: false,
    timer: localStorage.getItem("resendTimer") || 60,
    // message
    dialogData: {
      open: false,
      color: "primary",
      bodyText: "test",
    },
    // message
  }),
  created() {
    if (!localStorage.getItem("resendTimer")) {
      this.createSession();
    } else {
      this.resendCode();
    }
  },
  methods: {
    createSession() {
      localStorage.setItem("resendTimer", 60);
      this.resendCode();
    },
    resendCode() {
      const intervalId = setInterval(() => {
        if (this.timer > 0) {
          this.timer--;
        } else {
          clearInterval(intervalId);
          localStorage.removeItem("resendTimer");
        }
        localStorage.setItem("resendTimer", this.timer);
      }, 1000);
    },

    async retryOtp() {
      this.loadingRetryOtp = true;

      try {
        const response = await Api.retryOtp();
        console.log(response);
        this.loadingRetryOtp = false;
        this.timer = 60;
        localStorage.setItem("resendTimer", 60);
        this.resendCode();
      } catch (error) {
        this.showDialogfunction("خطاء، يرجى اعادة المحاولة", "#FF5252");
        this.loadingRetryOtp = false;
      }
    },

    async submit() {
      this.loading = true;

      try {
        const response = await Api.checkOtp(this.otp);
        this.loading = false;
        this.$router.push({ path: '/ar/login' });

    } catch (error) {
        this.showDialogfunction("خطاء، يرجى اعادة المحاولة", "#FF5252");
        this.loadingRetryOtp = false;
      }
    },
    showDialogfunction(bodyText, color) {
      this.dialogData.open = true;
      this.dialogData.bodyText = bodyText;
      this.dialogData.color = color;
    },
  },
};
</script>