<template>
  <v-container>
    <v-card class="text-right" title="الأعلانات">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      ></v-progress-linear>
      <carousel :items-to-show="1.5">
        <slide v-for="(Service, index) in Services" :key="index">
          <v-img
            :src="content_url + Service.image"
            height="315px"
            class="bg-white"
          >
            <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  v-if="loadingImg"
                  indeterminate
                  color="grey-lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
          </v-img>
        </slide>

        <template #addons>
          <navigation />
          <pagination />
        </template>
      </carousel>
    </v-card>
  </v-container>
</template>
  
  <script>
import "vue3-carousel/dist/carousel.css";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";
import Api from "@/api/api.js";

export default {
  name: "App",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  data() {
    return {
      Services: [],
      loading: false,
      loadingImg: false,
      content_url: "",
    };
  },
  created() {
    this.getServices();
  },

  methods: {
    async getServices() {
      this.loading = true;
      this.loadingImg = true;
      const response = await Api.getAds();
      this.Services = response.data.results;
      this.content_url = response.data.content_url;
      this.loading = false;
      this.loadingImg = false;

      console.log(response);
    },
  },
};
</script>
  