// Styles
import '@mdi/font/css/materialdesignicons.css';
import 'vuetify/styles';

// Vuetify
import { createVuetify } from 'vuetify';

export default createVuetify({
  // ... مواصفات أخرى لتكوين Vuetify

  // تحديد نوع المؤشر المستخدم
  icons: {
    default: 'mdi',
  },
});
