import axios from "axios";
const center_id = "65641d2553bbc97cbef588ff";
import CryptoJS from "crypto-js";

class Api {
  // login
  async register({ phone, password_show, name }) {
    const requestData = {
      phone: phone,
      name: name,
      password_show: password_show,
      center_id: "65641d2553bbc97cbef588ff",
    };
    const response = await axios.post(`/auth/register`, requestData);
    return response;
  }
  async retryOtp() {
    var phone = JSON.parse(localStorage.getItem("phone"));
    const requestData = {
      phone: phone,
      center_id: "65641d2553bbc97cbef588ff",
    };
    const response = await axios.post(`/auth/retry_otp`, requestData);
    return response;
  }
  async checkOtp(otp) {
    var phone = JSON.parse(localStorage.getItem("phone"));
    const requestData = {
      phone: phone,
      otp: otp,
    };
    const response = await axios.post(`/auth/check_otp`, requestData);
    return response;
  }
  async login({ phone, password }) {
    const hashedPassword = CryptoJS.SHA512(password).toString();
    const requestData = {
      phone: phone,
      password: hashedPassword,
      center_id: "65641d2553bbc97cbef588ff",
      auth_phone_id: "mustafa",
      auth_phone_details: "",
      auth_ip: "",
      auth_city: "",
      auth_lat: "",
      auth_lon: "",
      auth_firebase: "",
    };
    const response = await axios.post(`/auth/login`, requestData);
    return response;
  }
  async PasswordReset({ phone, password }) {
    // const hashedPassword = CryptoJS.SHA512(password).toString();
    const requestData = {
      phone: phone,
      password: password,
      center_id: "65641d2553bbc97cbef588ff",
    };
    const response = await axios.put(`/auth/change_password`, requestData);
    return response;
  }
  async logout() {
    const token = localStorage.getItem("tokenShahrazad");

    axios.defaults.headers.common.Authorization = JSON.parse(token);

    const response = await axios.get(`/auth/logout`);
    return response;
  }
  // login
  async airportsSearch({ search }) {
    const requestData = {
      center_id,
      search,
    };
    const response = await axios.post(`/airports/search`, requestData);
    return response;
  }
  // Services
  async getServices() {
    const response = await axios.get(`/services/center_id/${center_id}`);
    return response;
  }
  async getOneService(id) {
    const response = await axios.get(`/services/service_id/${id}`);
    return response;
  }
  // Services
  // ads
  async getAds() {
    const response = await axios.get(`/ads/center_id/${center_id}`);
    return response;
  }
  // ads
  // ContactUs
  async getContactUs() {
    const response = await axios.get(`/contact_us/center_id/${center_id}`);
    return response;
  }
  // ContactUs
  // Center
  // async addCenter({ name, phone, address }) {
  //   const requestData = {
  //     name,
  //     phone,
  //     address,
  //   };
  //   const response = await axiosInstance.post(`/admin/centers`, requestData);
  //   return response;
  // }
  // async editCenter({ center_id, name, phone, address }) {
  //   const requestData = {
  //     center_id,
  //     name,
  //     phone,
  //     address,
  //   };
  //   const response = await axiosInstance.put(`/admin/centers`, requestData);
  //   return response;
  // }

  // async removeCenter(id) {
  //   const response = await axiosInstance.delete(
  //     `/admin/centers/center_id/${id}`
  //   );
  //   return response;
  // }
  // Center
}

export default new Api();
