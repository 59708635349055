<template>
  <v-row justify="center">
    <v-app-bar app>
      <v-bottom-navigation>
        <router-link to="/ar" exact>
          <v-btn value="recent" class="bottom-nav-btn">
            <v-icon>mdi-home</v-icon>
            <span>الصفحة الرئيسية</span>
          </v-btn>
        </router-link>

        <router-link to="/ar/services" exact>
          <v-btn value="services" class="bottom-nav-btn">
            <v-icon>mdi-airplane</v-icon>
            <span>رحلاتي</span>
          </v-btn>
        </router-link>

        <router-link to="/ar/profile" exact>
          <v-btn value="profile" class="bottom-nav-btn">
            <v-icon>mdi-account</v-icon>
            <span>الملف الشخصي</span>
          </v-btn>
        </router-link>
      </v-bottom-navigation>
    </v-app-bar>
  </v-row>
</template>
