<template>
  <v-card  title="Search for a trip" variant="outlined">
    <v-card-text>
      <v-card text="Select the trip type" variant="outlined">
        <v-container>
          <v-row class="pa-4">
            <v-radio-group v-model="model" inline>
              <v-row justify="start">
                <v-radio value="1" label="going and coming back"></v-radio>
                <v-radio value="0" label="Go only"></v-radio>
              </v-row>
            </v-radio-group>
          </v-row>
        </v-container>
      </v-card>
      <br />
      <v-card text="Specify the journey principle." class="trx" variant="outlined">
        <v-container>
          <v-row>
            <v-col cols="12" md="5">
              <v-text-field
                model-value=""
                label="Landing airport"
                clearable
                class="d"
                variant="solo-filled"
                @click="getDepartureAirportDrop"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" class="text-center">
              <v-icon size="50">mdi-arrow-left-box</v-icon>
            </v-col>
            <v-col cols="12" md="5">
              <v-text-field
                model-value=""
                label="Departure airport"
                clearable
                variant="solo-filled"
                class="d"
                @click="getDepartureAirport"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <br />
      <v-card text="Select the flight date" class="trx" variant="outlined">
        <v-container>
          <v-row>
            <v-col cols="12" md="5" class="text-end">
              <div>Return date</div>
              <br />
              <br />
              <VueDatePicker clearable v-model="date" :teleport="true" />
            </v-col>
            <v-col cols="12" md="2" class="text-center">
              <v-icon size="50">mdi-clock-time-eight</v-icon>
            </v-col>
            <v-col cols="12" md="5" class="text-end">
              <div>Departure Date</div>
              <br />
              <br />
              <VueDatePicker clearable v-model="date" :teleport="true" />
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <br />
      <v-card
        @click="dialog = true"
        text="Number of passengers and cabin type"
        variant="outlined"
      >
        <v-container>
          <div
            style="
              display: flex;
              flex-direction: row-reverse;
              align-items: center;
            "
          >
            <v-icon size="30">mdi-account-outline</v-icon>
            <div>
              <strong>(1) Passenger, class type (economy class)</strong>
            </div>
          </div>
        </v-container>
      </v-card>
    </v-card-text>
    <v-card-actions>
      <v-btn
        :disabled="loading"
        :loading="loading"
        block
        class="text-none mb-4"
        color="#5865f2"
        size="x-large"
        append-icon="mdi mdi-text-search"
        variant="flat"
      >
      research
      </v-btn>
    </v-card-actions>
  </v-card>
  <v-dialog v-model="dialog" max-width="800">
    <v-card>
      <v-card-title class="text-end">Travelers -</v-card-title>
      <v-card-text>
        <div>
          <v-row
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
            "
          >
            <v-col
              cols="6"
              md="6"
              class="text-end"
              style="
                height: 55px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <div><strong> grown ups </strong></div>
              <div><span>Older than (12) years old</span></div>
            </v-col>
            <v-col cols="6" md="6" class="text-start">
              <v-btn size="30" icon="mdi mdi-minus"> </v-btn>
              <strong style="margin-inline: 20px">( 1 )</strong>
              <v-btn size="30" icon="mdi mdi-plus"> </v-btn>
            </v-col>
          </v-row>
          <v-row
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
            "
          >
            <v-col
              cols="6"
              md="6"
              class="text-end"
              style="
                height: 55px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <div><strong> child </strong></div>
              <div><span>From (2) to (12)</span></div>
            </v-col>
            <v-col cols="6" md="6" class="text-start">
              <v-btn size="30" icon="mdi mdi-minus"> </v-btn>
              <strong style="margin-inline: 20px">( 1 )</strong>
              <v-btn size="30" icon="mdi mdi-plus"> </v-btn>
            </v-col>
          </v-row>
          <v-row
            style="
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
            "
          >
            <v-col
              cols="6"
              md="6"
              class="text-end"
              style="
                height: 55px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <div><strong> infant </strong></div>
              <div><span>Less than two years</span></div>
            </v-col>
            <v-col cols="6" md="6" class="text-start">
              <v-btn size="30" icon="mdi mdi-minus"> </v-btn>
              <strong style="margin-inline: 20px">( 1 )</strong>
              <v-btn size="30" icon="mdi mdi-plus"> </v-btn>
            </v-col>
          </v-row>
        </div>
        <br />
        <br />
        <br />
        <div>
          <v-card-title class="text-end">Travel class -</v-card-title>

          <v-radio-group v-model="model2">
            <v-radio
              style="justify-content: space-between"
              label="economy class"
              value="3"
            ></v-radio>
            <v-radio
              style="justify-content: space-between"
              label="first degree"
              value="1"
            ></v-radio>
            <v-radio
              style="justify-content: space-between"
              label="business class"
              value="2"
            ></v-radio>
          </v-radio-group>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          :disabled="loading"
          :loading="loading"
          block
          class="text-none mb-4"
          color="#5865f2"
          size="x-large"
          variant="flat"
        >
          تم
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="dialogA" max-width="800">
    <v-card v-if="Departure == false">
      <v-card-title class="text-end"> Select the start of the journey </v-card-title>
      <v-card-text>
        <VTextField
          
          v-model="search"
          append-icon="mdi-airplane"
          label="research"
          variant="outlined"
          clearable
          @input="getDepartureAirport"
        />
        <v-list
          density="compact"
          v-if="
            IResearchedPotentialAirport &&
            IResearchedPotentialAirport.length > 0
          "
          class="text-right ca"
        >
          <v-list-subheader class="text-right"
            >Recent search results</v-list-subheader
          >

          <v-list-item
            v-for="(
              IResearchedPotentialAirpor, index
            ) in IResearchedPotentialAirport"
            :key="index"
            :value="IResearchedPotentialAirpor"
            color="primary"
            variant="outlined"
            @click="ChooseYourFirstAirport(IResearchedPotentialAirpor)"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-airplane"></v-icon>
            </template>

            <v-list-item-title
              v-text="IResearchedPotentialAirpor.en_name"
            ></v-list-item-title>
            <v-card-text>
              <span
                >{{ IResearchedPotentialAirpor.country_info.en_name }},
                {{ IResearchedPotentialAirpor.city_info.en_name }}</span
              >
            </v-card-text>
          </v-list-item>
        </v-list>
        <v-list
          density="compact"
          class="text-right ca"
          v-if="dataAirport && dataAirport.length > 0"
        >
          <v-list-subheader class="text-right">The beginning of the journey</v-list-subheader>

          <v-list-item
            v-for="(dataAirpor, index) in dataAirport"
            :key="index"
            :value="dataAirpor"
            color="primary"
            variant="outlined"
            @click="ChooseYourFirstAirport(dataAirpor)"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-airplane"></v-icon>
            </template>

            <v-list-item-title v-text="dataAirpor.en_name"></v-list-item-title>
            <v-card-text>
              <span
                >{{ dataAirpor.country_info.en_name }},
                {{ dataAirpor.city_info.en_name }}</span
              >
            </v-card-text>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
    <v-card v-if="Departure == true">
      <v-card-title class="text-end"> Select the flight destination </v-card-title>
      <v-card-text>
        <VTextField
          
          v-model="search"
          append-icon="mdi-airplane"
          label="research"
          variant="outlined"
          clearable
          @input="getDepartureAirport"
        />
        <v-list
          density="compact"
          v-if="LandingSearchResults && LandingSearchResults.length > 0"
          class="text-right ca"
        >
          <v-list-subheader class="text-right"
            >Recent search results</v-list-subheader
          >

          <v-list-item
            v-for="(LandingSearchResult, index) in LandingSearchResults"
            :key="index"
            :value="LandingSearchResult"
            color="primary"
            variant="outlined"
            @click="ChooseTheSecondAirport(LandingSearchResult)"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-airplane"></v-icon>
            </template>

            <v-list-item-title
              v-text="LandingSearchResult.en_name"
            ></v-list-item-title>
            <v-card-text>
              <span
                >{{ LandingSearchResult.country_info.en_name }},
                {{ LandingSearchResult.city_info.en_name }}</span
              >
            </v-card-text>
          </v-list-item>
        </v-list>
        <v-list
          density="compact"
          class="text-right ca"
          v-if="dataAirporDrop && dataAirporDrop.length > 0"
        >
          <v-list-subheader class="text-right">Trip destination</v-list-subheader>

          <v-list-item
            v-for="(dataAirporDro, index) in dataAirporDrop"
            :key="index"
            :value="dataAirporDro"
            color="primary"
            variant="outlined"
            @click="ChooseTheSecondAirport(dataAirporDro)"
          >
            <template v-slot:prepend>
              <v-icon icon="mdi-airplane"></v-icon>
            </template>

            <v-list-item-title v-text="dataAirporDro.en_name"></v-list-item-title>
            <v-card-text>
              <span
                >{{ dataAirporDro.country_info.en_name }},
                {{ dataAirporDro.city_info.en_name }}</span
              >
            </v-card-text>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import Api from "@/api/api.js";

export default {
  data() {
    return {
      model: "0",
      model2: "3",
      date: null,
      search: "",
      dataAirport: [],
      dataAirporDrop: [],
      IResearchedPotentialAirport: JSON.parse(
        localStorage.getItem("IResearchedPotentialAirport")
      ),
      LandingSearchResults: JSON.parse(
        localStorage.getItem("LandingSearchResults")
      ),
      dialog: false,
      Departure: false,
      dialogA: false,
      loading: false,
    };
  },
  created() {
    // this.getDepartureAirport();
  },

  methods: {
    async getDepartureAirportDrop() {
      this.dialogA = true;
      this.Departure = true;
    },
    async getDepartureAirport() {
      const response = await Api.airportsSearch({
        search: this.search,
      });
      this.dataAirport = response.data.results;
      this.dataAirporDrop = response.data.results;

      this.dialogA = true;
    },
    ChooseYourFirstAirport(item) {
      var storedData =
        JSON.parse(localStorage.getItem("IResearchedPotentialAirport")) || [];

      var isDuplicate = storedData.some(
        (existingItem) => existingItem._id === item._id
      );

      if (!isDuplicate) {
        storedData.push(item);

        localStorage.setItem(
          "IResearchedPotentialAirport",
          JSON.stringify(storedData)
        );
      }
      this.IResearchedPotentialAirport = JSON.parse(
        localStorage.getItem("IResearchedPotentialAirport")
      );

      this.Departure = true;
      this.search = "";
      console.log(item);
    },
    ChooseTheSecondAirport(item) {
      var storedData =
        JSON.parse(localStorage.getItem("LandingSearchResults")) || [];

      var isDuplicate = storedData.some(
        (existingItem) => existingItem._id === item._id
      );

      if (!isDuplicate) {
        storedData.push(item);

        localStorage.setItem(
          "LandingSearchResults",
          JSON.stringify(storedData)
        );
      }
      this.LandingSearchResults = JSON.parse(
        localStorage.getItem("LandingSearchResults")
      );

      this.Departure = false;
      this.search = "";
    },
  },
};
</script>

<style>
.dp--menu-wrapper {
  transform: translate(100%, 0%) !important;
}
/* .v-card-text {
  line-height: 0.25rem !important;
}
.v-card-text {
  flex: 1 1 auto;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 0.0178571429em;
  padding: 15px;
  text-transform: none;
} */
/* 
.d .v-input__control {
  border: solid 1px #959595;
  color: black;
}
.d .v-input__control .v-label {
  color: black;
} */
.v-card--variant-outlined {
  border: solid 1px #00000026 !important;
}
.ca {
  margin-bottom: 12px;
}
.ca .v-card-text {
  line-height: 0.25rem;
  margin: 0px;
}
.ca .v-list-item--density-compact:not(.v-list-item--nav).v-list-item--one-line {
  margin-bottom: 5px;
}
@media (min-width: 100px) {
  .trx .v-row {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    margin: -12px;
    flex-direction: column-reverse;
    align-items: flex-end;
  }
}
@media (min-width: 768px) {
  .trx .v-row {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    margin: -12px;
    flex-direction: row;
    align-items: flex-end;
  }
}
</style>